import React from 'react';
import styled from 'styled-components'

const PlusContainer = styled.div`
    position: relative;
    height: 16px;
`

const PlusVertical = styled.div`
    width: 2px;
    height: 16px;
    background: #4285F4;
    position: absolute;
`

const PlusHorizontal = styled.div`
    width: 2px;
    height: 16px;
    background: #4285F4;
    transform: rotate(90deg);
    position: absolute;
`

const Plus = (props) => {
    return (
        <PlusContainer>
            <PlusVertical />
            <PlusHorizontal />
        </PlusContainer>
    )
}

export default Plus;