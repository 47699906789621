import React, {useState} from 'react'
import { graphql } from "gatsby";

import Business from "../components/Business";
import FormBlock from "../components/Form";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import "../components/layout.css";
import Seo from "../components/seo";


import {ContainerL} from '../ui/containers';
import Modal from "../ui/modal";





const BusinessPage = ({data}) => {
    const [openModal, setOpenModal] = useState(false);
    const fullData = data.allStrapiBusiness.nodes[0].blocks;
    console.log(fullData)

    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    }
    return (
        <div>
            <Seo title={"Для бизнеса"} />
            <Header/>
            {
                openModal ?
                    (<Modal toggleOpenModal={toggleOpenModal}/>)
                    :
                    (
                        <>
                            <Business DATA={fullData}/>
                            <ContainerL style={{margin: "240px auto 120px"}}>
                                <FormBlock />
                            </ContainerL>
                            <Footer toggleOpenModal={toggleOpenModal}/>
                        </>
                    )}

        </div>
    )
}

export default BusinessPage;

export const query = graphql`
    query BuisnessQuery {
        allStrapiBusiness {
            nodes {
                blocks {
                    title
                    id
                    description
                    image {
                        localFile {
                           publicURL
                        }
                    }
                }
            }
        }
    }

`
