import React from 'react';
import Plus from "../components/ActionCards/Plus";

const PlusComponent = ({text}) => {
    return (
        <div style={{paddingLeft: 8, display: "flex", flexDirection: "row", marginBottom: 10}}>
            <Plus/>
            <div style={{
                margin: "30px 0 0 15px",
                fontSize: 16,
                color: "#FFFFFF",
                fontWeight: 400,
                textTransform: "uppercase"

            }}>
                {text}
            </div>
        </div>
    );
};

export default PlusComponent;
